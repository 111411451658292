import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Container } from "react-bootstrap";
import Zoom from "react-medium-image-zoom";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { CardBody, CardContainer, CardItem } from "./3dCard";
import { Skeleton } from "./Icon";
import { LampContainer } from "./Lamb";
import { ShinyButton } from "./ShinyButton";
import AnimatedShinyText from "./ShinyText";
import { motion } from "framer-motion";

import ChromaMix from "../../Assets/Projects/ChromaMix.png";
import CodeBuddy from "../../Assets/Projects/CodeBuddy.png";
import Flavorie from "../../Assets/Projects/Flavorie.png";
import InternshipForum from "../../Assets/Projects/InternshipForum.png";
import InternshipForumV1 from "../../Assets/Projects/InternshipForumV1.png";
import SeamLessLMS from "../../Assets/Projects/SeamLessLMS.png";
import TripSync from "../../Assets/Projects/TripSync.png";
import BootstrapIcon from "../../Assets/TechStackLogo/Bootstrap.svg";
import CssIcon from "../../Assets/TechStackLogo/CSS.svg";
import DjangoIcon from "../../Assets/TechStackLogo/Django.svg";
import DockerIcon from "../../Assets/TechStackLogo/Docker.svg";
import EJSIcon from "../../Assets/TechStackLogo/EJS.svg";
import ExpressjsIcon from "../../Assets/TechStackLogo/Express.svg";
import FirebaseIcon from "../../Assets/TechStackLogo/Firebase.svg";
import FlutterIcon from "../../Assets/TechStackLogo/Flutter.svg";
import FramerIcon from "../../Assets/TechStackLogo/Framer.svg";
import GitIcon from "../../Assets/TechStackLogo/Git.svg";
import HtmlIcon from "../../Assets/TechStackLogo/HTML.svg";
import MapBoxIcon from "../../Assets/TechStackLogo/MapBox.svg";
import MongoDBIcon from "../../Assets/TechStackLogo/MongoDB.svg";
import MySQLIcon from "../../Assets/TechStackLogo/MySQL.svg";
import NextJSIcon from "../../Assets/TechStackLogo/NextJS.svg";
import PrismaIcon from "../../Assets/TechStackLogo/Prisma.svg";
import PuppeteerIcon from "../../Assets/TechStackLogo/Puppeteer.svg";
import ReactjsIcon from "../../Assets/TechStackLogo/React.svg";
import ReacthookformIcon from "../../Assets/TechStackLogo/Reacthookform.svg";
import ReactqueryIcon from "../../Assets/TechStackLogo/Reactquery.svg";
import RedisIcon from "../../Assets/TechStackLogo/Redis.svg";
import ReduxIcon from "../../Assets/TechStackLogo/Redux.svg";
import SQLAlchemyIcon from "../../Assets/TechStackLogo/SQLAlchemy.svg";
import SocketIOIcon from "../../Assets/TechStackLogo/SocketIO.svg";
import StylecomponentIcon from "../../Assets/TechStackLogo/Stylecomponent.svg";
import TailwindIcon from "../../Assets/TechStackLogo/Tailwind.svg";
import WebrtcIcon from "../../Assets/TechStackLogo/Webrtc.svg";
import Footer from "../Footer";
import Particle from "../Particle";
import ToolStack from "./Toolstack";

import "atropos/css";
import "react-medium-image-zoom/dist/styles.css";

const Wrapper = styled.section`
  overflow-y: hidden;
  overflow-x: hidden;
`;

function Projects() {
  const projectDescription = [
    {
      title: "CodeBuddy",
      description: "A Chrome extension for live teamwork on LeetCode problems.",
      image: CodeBuddy,
      localLink: "/project/CodeBuddy",
      link: "https://github.com/hungngodev/Code-Buddy-Prod",
      logos: [
        {
          Component: ReactjsIcon,
          size: 4, // h-4 w-4
        },
        {
          Component: FramerIcon,
          size: 6, // h-6 w-6
        },
        {
          Component: FirebaseIcon,
          size: 8, // h-8 w-8
        },
        {
          Component: WebrtcIcon,
          size: 6, // h-6 w-6
        },
        {
          Component: TailwindIcon,
          size: 4, // h-4 w-4
        },
      ],
    },
    {
      title: "Flavorie",
      description:
        "Cook with others live and effortlessly, and share your moments with everyone.",
      image: Flavorie,
      localLink: "/project/Flavorie",
      link: "https://flavorie.xyz",
      logos: [
        {
          Component: NextJSIcon,
          size: 4, // h-4 w-4
        },
        {
          Component: SocketIOIcon,
          size: 6, // h-6 w-6
        },
        {
          Component: RedisIcon,
          size: 8, // h-8 w-8
        },
        {
          Component: MongoDBIcon,
          size: 6, // h-6 w-6
        },
        {
          Component: DockerIcon,
          size: 4, // h-4 w-4
        },
      ],
    },
    {
      title: "TripSync",
      description:
        "A mobile app that helps you plan your trip with friends and family.",
      image: TripSync,
      localLink: "/project/TripSync",
      link: "https://github.com/hungngodev/TripSync",
      logos: [
        {
          Component: PuppeteerIcon,
          size: 4, // h-4 w-4
        },
        {
          Component: DjangoIcon,
          size: 6, // h-6 w-6
        },
        {
          Component: FlutterIcon,
          size: 8, // h-8 w-8
        },
        {
          Component: MySQLIcon,
          size: 6, // h-6 w-6
        },
        {
          Component: ReduxIcon,
          size: 4, // h-4 w-4
        },
      ],
    },
    {
      title: "Internship Forum",
      description:
        "A web platform designed for everyone to share, explore, and review internship.",
      image: InternshipForum,
      localLink: "/project/InternshipForum",
      link: "https://internshipforum-80226f6855ff.herokuapp.com",
      logos: [
        {
          Component: ReactjsIcon,
          size: 4, // h-4 w-4
        },
        {
          Component: ExpressjsIcon,
          size: 6, // h-6 w-6
        },
        {
          Component: ReactqueryIcon,
          size: 8, // h-8 w-8
        },
        {
          Component: MongoDBIcon,
          size: 6, // h-6 w-6
        },
        {
          Component: MapBoxIcon,
          size: 4, // h-4 w-4
        },
      ],
    },
    {
      title: "SeamLess LMS",
      description:
        "A role-based Learning Management System website for online education.",
      image: SeamLessLMS,
      localLink: "/project/SeamLessLMS",
      link: "https://github.com/Seamless-Tech",
      logos: [
        {
          Component: NextJSIcon,
          size: 4, // h-4 w-4
        },
        {
          Component: ReacthookformIcon,
          size: 6, // h-6 w-6
        },
        {
          Component: TailwindIcon,
          size: 8, // h-8 w-8
        },
        {
          Component: PrismaIcon,
          size: 6, // h-6 w-6
        },
        {
          Component: SQLAlchemyIcon,
          size: 4, // h-4 w-4
        },
      ],
    },
    {
      title: "ChromaMix",
      description:
        "A simple website that generates a random color palette with tint and shades.",
      image: ChromaMix,
      localLink: "/project/ChromaMix",
      link: "https://hungngodev.github.io/ChromaMix",
      logos: [
        {
          Component: ReactjsIcon,
          size: 4, // h-4 w-4
        },
        {
          Component: StylecomponentIcon,
          size: 6, // h-6 w-6
        },
        {
          Component: BootstrapIcon,
          size: 8, // h-8 w-8
        },
        {
          Component: GitIcon,
          size: 6, // h-6 w-6
        },
        {
          Component: CssIcon,
          size: 4, // h-4 w-4
        },
      ],
    },
    {
      title: "Internship Forum V1",
      description: "Previous version: HTML, CSS, JavaScript for frontend.",
      image: InternshipForumV1,
      localLink: "/project/InternshipForumV1",
      link: "https://internshipforumv1-1bc3d87eae50.herokuapp.com/internships/new",
      logos: [
        {
          Component: HtmlIcon,
          size: 4, // h-4 w-4
        },
        {
          Component: EJSIcon,
          size: 6, // h-6 w-6
        },
        {
          Component: ExpressjsIcon,
          size: 8, // h-8 w-8
        },
        {
          Component: MongoDBIcon,
          size: 6, // h-6 w-6
        },
        {
          Component: CssIcon,
          size: 4, // h-4 w-4
        },
      ],
    },
  ];

  return (
    <div className="h-full w-full bg-slate-950 overflow-hidden">
      <Container className="w-full h-full flex items-center justify-center flex-col">
        <div className="w-full translate-y-20">
          <LampContainer>
            <Particle />
            <motion.h1
              initial={{ opacity: 0.5, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{
                delay: 0.3,
                duration: 0.8,
                ease: "easeInOut",
              }}
              className="mt-8 bg-gradient-to-br from-slate-300 to-slate-500 py-4 bg-clip-text text-center text-4xl font-medium tracking-tight text-transparent md:text-7xl"
            >
              <h1 className="text-white text-4xl font-medium font-serif pt-2">
                My Recent
                <strong className="purple"> Projects</strong>
              </h1>
            </motion.h1>
          </LampContainer>
        </div>

        <div className="grid grid-cols-2 w-full gap-x-4 px-4 sm:grid-cols-1 lg:grid-cols-2 -translate-y-[20vh]">
          {projectDescription.map((image, index) => (
            <CardContainer
              className="inter-var m-0"
              key={index}
              containerClassName={"py-10"}
            >
              <CardBody className="bg-gray-50 m-0 relative group/card  dark:hover:shadow-2xl dark:hover:shadow-emerald-500/[0.1] dark:bg-black dark:border-white/[0.2] border-black/[0.1] w-[35vw] h-[35vw] rounded-xl p-6 border  ">
                <CardItem translateZ="50" className=" w-full ">
                  <div className="flex justify-between w-full">
                    <p className="font-bold text-neutral-600 dark:text-white text-xl">
                      {image.title}
                    </p>
                    <Skeleton logos={image.logos} />
                  </div>
                </CardItem>
                <CardItem
                  as="p"
                  translateZ="60"
                  className="text-neutral-500 text-sm max-w-sm mt-2 dark:text-neutral-300"
                >
                  {image.description}
                </CardItem>
                <CardItem translateZ="100" className="w-full mt-4">
                  <Zoom>
                    <img
                      src={image.image}
                      height="1000"
                      width="1000"
                      className="h-60 w-full object-cover rounded-xl group-hover/card:shadow-xl"
                      alt="thumbnail"
                    />
                  </Zoom>
                </CardItem>

                <div className="flex justify-between items-center mt-[4vh]">
                  <CardItem
                    translateZ={20}
                    as={Link}
                    href={image.link}
                    target="__blank"
                    className="px-4 rounded-xl text-xs "
                  >
                    <Link to={image.link}>
                      <AnimatedShinyText
                        shimmerWidth={120}
                        className="transition ease-out hover:text-yellow-400 hover:duration-300"
                      >
                        <span>✨ Try now →</span>
                      </AnimatedShinyText>
                    </Link>
                  </CardItem>
                  <Link>
                    <CardItem
                      translateZ={20}
                      as={Link}
                      href={image.localLink}
                      className="px-4 rounded-xl bg-black dark:bg-white dark:text-black text-white text-xs font-bold"
                    >
                      <Link to={image.localLink}>
                        <ShinyButton>Details</ShinyButton>
                      </Link>
                    </CardItem>
                  </Link>
                </div>
              </CardBody>
            </CardContainer>
          ))}
        </div>
        <h1 className=" mb-5 text-white text-2xl font-medium font-serif pt-2">
          My Favorite <strong className="purple">Tools</strong>
        </h1>
        <ToolStack />
      </Container>
      <Footer />
    </div>
  );
}
export default Projects;
