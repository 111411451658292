import { Button } from "@mui/material";
import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import React from "react";
import Image from "react-bootstrap/Image";
import styled from "styled-components";

import Moon from "../../Assets/homePageParallax/moon.png";
import mountains_behind from "../../Assets/homePageParallax/mountains_behind.png";
import mountains_front from "../../Assets/homePageParallax/mountains_front.png";
import stars from "../../Assets/homePageParallax/stars.png";
import laptopAbout from "../../Assets/laptopAbout.svg";
import Navbar from "../Navbar";
import Particle from "../Particle";
import Aboutcard from "./AboutCard";
import Contact from "./Contact";
import Hero from "./Hero";
import { AspectRatio, calculatingAR, objectStage } from "./ResponsiveConfig";
import Techstack from "./Techstack";
import Type from "./Type";

const Wrapper = styled.section`
  overflow-x: hidden;
  #tsparticles {
    position: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
  }

  .home-section {
    position: relative;
    z-index: -1;
    background-position: top center;
    background-repeat: no-repeat;
    padding-bottom: 30px;
    padding-top: 30px;
  }

  .heading {
    font-size: 2.4em;
    padding-left: 50px;
  }

  .layer {
    border-top: 2px solid #1de9b6;
  }
`;
const ArrowWrapper = styled.section`
  .arrows {
    width: min(5vw, 5vh);
    height: auto;
  }

  .arrows path {
    fill: transparent;
    animation: arrow 2s infinite;
    -webkit-animation: arrow 2s infinite;
  }

  @keyframes arrow {
    0% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

  .arrows path.a1 {
    stroke: #ff6484;
    stroke-width: 3px;
    animation-delay: -1s;
    -webkit-animation-delay: -1s; /* Safari 和 Chrome */
  }

  .arrows path.a2 {
    stroke: #ffcd56;
    stroke-width: 5px;
    animation-delay: -0.5s;
    -webkit-animation-delay: -0.5s; /* Safari 和 Chrome */
  }

  .arrows path.a3 {
    stroke: #4bc0c0;
    stroke-width: 6px;
    animation-delay: 0s;
    -webkit-animation-delay: 0s; /* Safari 和 Chrome */
  }
  .arrows path.a6 {
    stroke: #ff6484;
    stroke-width: 3px;
    animation-delay: -1s;
    -webkit-animation-delay: -1s; /* Safari 和 Chrome */
  }

  .arrows path.a5 {
    stroke: #ffcd56;
    stroke-width: 5px;
    animation-delay: -0.5s;
    -webkit-animation-delay: -0.5s; /* Safari 和 Chrome */
  }

  .arrows path.a4 {
    stroke: #4bc0c0;
    stroke-width: 6px;
    animation-delay: 0s;
    -webkit-animation-delay: 0s; /* Safari 和 Chrome */
  }
`;
function Home() {
  const parallaxRef = React.useRef(null);
  const [stage, setStage] = React.useState(0);
  const [checked, setChecked] = React.useState(false);
  const { innerWidth: width, innerHeight: height } = window;
  let aspectRatio = calculatingAR(width / height);
  console.log(aspectRatio);
  const config = AspectRatio[aspectRatio];
  const currentStage = objectStage[aspectRatio];

  React.useEffect(() => {
    document.body.style.overflow = "hidden";
    function handleResize() {
      window.location.reload();
    }
    window.addEventListener("resize", handleResize);
    return () => {
      document.body.style.overflow = "scroll";
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const moveDown = () => {
    if (stage !== 4) {
      parallaxRef.current.scrollTo(currentStage[stage + 1]);
      setStage(stage + 1);
    }
  };
  const moveUp = () => {
    if (stage !== 0) {
      parallaxRef.current.scrollTo(currentStage[stage - 1]);
      setStage(stage - 1);
    }
  };
  const MoveHandle = (stage) => {
    parallaxRef.current.scrollTo(currentStage[stage]);
    setStage(stage);
    return;
  };
  return (
    <Wrapper>
      <Navbar
        home={{
          checked: checked,
          setChecked: setChecked,
          MoveHandle: MoveHandle,
        }}
      />
      {!checked && (
        <Button
          onClick={moveUp}
          style={{ position: "absolute", right: "2%", top: "10%", zIndex: 100 }}
        >
          <ArrowWrapper>
            <svg
              className="arrows"
              viewBox="0 0 60 72"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path className="a4" d="M0 32 L30 0 L60 32"></path>
              <path className="a5" d="M0 52 L30 20 L60 52"></path>
              <path className="a6" d="M0 72 L30 40 L60 72"></path>
            </svg>
          </ArrowWrapper>
        </Button>
      )}
      {!checked && (
        <Button
          onClick={moveDown}
          style={{ position: "absolute", right: "2%", top: "20%", zIndex: 100 }}
        >
          <ArrowWrapper>
            <svg
              className="arrows"
              viewBox="0 0 60 72"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path className="a1" d="M0 0 L30 32 L60 0"></path>
              <path className="a2" d="M0 20 L30 52 L60 20"></path>
              <path className="a3" d="M0 40 L30 72 L60 40"></path>
            </svg>
          </ArrowWrapper>
        </Button>
      )}
      <Parallax
        ref={parallaxRef}
        pages={config.pages}
        style={{
          top: "10",
          left: "0",
          marginTop: "20px",
          overflowY: !checked ? "hidden" : "auto",
          overflowX: "hidden",
        }}
      >
        <ParallaxLayer
          {...config.layer1}
          style={{
            backgroundImage: `url(${stars})`,
            backgroundSize: "cover",
          }}
        >
          <ParallaxLayer {...config.layer2} style={{ zIndex: 400 }}>
            <Hero onClickF={MoveHandle} />
          </ParallaxLayer>
          <ParallaxLayer
            {...config.layer3}
            style={{
              backgroundImage: `url(${Moon})`,
              backgroundSize: "contain",
              backgroundPosition: "top",
              position: "relative",
              zIndex: -10,
            }}
            factor={2}
          ></ParallaxLayer>
          <ParallaxLayer
            {...config.layer4}
            style={{
              backgroundImage: `url(${mountains_behind})`,
              backgroundSize: "contain",
            }}
          >
            <Image
              src={mountains_behind}
              style={{ objectFit: "contain" }}
              fluid
            />
            <div
              style={{
                background: "linear-gradient(to bottom, #5b63c4, #555bbf) ",
                width: "100%",
                height: "50%",
              }}
            />
          </ParallaxLayer>
          <ParallaxLayer {...config.layer6}>
            <Type />
          </ParallaxLayer>
          <ParallaxLayer {...config.layer5} factor={2}>
            <img
              src={mountains_front}
              style={{
                objectFit: "contain",
                width: "100%",
                position: "relative",
                zIndex: -1,
              }}
              alt=""
            />
            <div
              style={{
                backgroundColor: "black",
                width: "100%",
                height: "100%",
              }}
            />
          </ParallaxLayer>
          <ParallaxLayer
            offset={config.layer7.offset}
            speed={config.layer7.speed}
            horizontal={true}
          >
            <div
              style={{
                background: "transparent",
                width: "100%",
                height: config.layer7.height,
              }}
            />
            <img
              src={laptopAbout}
              style={{
                objectFit: "contain",
                width: config.layer7.width,
                position: "relative",
                zIndex: 5,
              }}
              alt=""
            />
          </ParallaxLayer>
          <ParallaxLayer
            offset={config.layer8.offset}
            speed={config.layer8.speed}
            horizontal={true}
          >
            <div
              style={{
                background: "transparent",
                width: "100%",
                height: "320%",
              }}
            />
            <Aboutcard alignCustom={config.layer8.alignStart} />
          </ParallaxLayer>
        </ParallaxLayer>
        <ParallaxLayer {...config.layer9} factor={4}>
          <Particle />
        </ParallaxLayer>
        <ParallaxLayer
          className="layer"
          {...config.layer10}
          style={{
            backgroundColor: "#282A36",
            zIndex: 600,
          }}
          factor={2}
        >
          <Particle />
          <Techstack />
        </ParallaxLayer>
        <ParallaxLayer
          className="layer"
          {...config.layer11}
          style={{
            backgroundColor: "#000",
            position: "relative",
            zIndex: 600,
          }}
          factor={2}
        >
          <Particle />
          <Contact />
        </ParallaxLayer>
      </Parallax>
    </Wrapper>
  );
}

export default Home;
