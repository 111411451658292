import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import Preloader from "../src/components/Pre";
import Home from "./components/Home/Home";
import Navbar from "./components/Navbar";
import ProjectCards from "./components/Projects/ProjectCards";
import Projects from "./components/Projects/Projects";
import Resume from "./components/Resume/ResumeNew";
import ScrollToTop from "./components/ScrollToTop";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./style.css";

function App() {
  const [load, updateLoad] = useState(true);
  const location = useLocation();
  useEffect(() => {
    updateLoad(location.pathname === "/" ? true : false);
    const timer = setTimeout(() => {
      localStorage.setItem("firstLoad", "true");
      updateLoad(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, [location]);
  const customElement = (outLet) => {
    return (
      <>
        <Navbar />
        {outLet}
      </>
    );
  };
  return (
    <>
      {load ? (
        <Preloader />
      ) : (
        <div>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/contact"
              element={<Navigate to="/" state={{ key: "contact" }} />}
            />
            <Route path="/project" element={customElement(<Projects />)} />
            <Route
              path="/project/:projectName"
              element={customElement(<ProjectCards />)}
            />
            <Route path="/resume" element={customElement(<Resume />)} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
      )}
    </>
  );
}

export default App;
