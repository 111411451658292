import { motion } from "framer-motion";
import React from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { Tabs } from "../ui/Tab";

import Awsec2Icon from "../../Assets/TechStackLogo/Awsec2.svg";
import BootstrapIcon from "../../Assets/TechStackLogo/Bootstrap.svg";
import CssIcon from "../../Assets/TechStackLogo/CSS.svg";
import CeleryIcon from "../../Assets/TechStackLogo/Celery.svg";
import ChartJSIcon from "../../Assets/TechStackLogo/ChartJS.svg";
import DockerIcon from "../../Assets/TechStackLogo/Docker.svg";
import EJSIcon from "../../Assets/TechStackLogo/EJS.svg";
import ExpressjsIcon from "../../Assets/TechStackLogo/Express.svg";
import FastAPIIcon from "../../Assets/TechStackLogo/FastAPI.svg";
import FirebaseIcon from "../../Assets/TechStackLogo/Firebase.svg";
import FlaskIcon from "../../Assets/TechStackLogo/Flask.svg";
import FramerIcon from "../../Assets/TechStackLogo/Framer.svg";
import GitIcon from "../../Assets/TechStackLogo/Git.svg";
import GithubactionIcon from "../../Assets/TechStackLogo/Githubaction.svg";
import GoogleCloudIcon from "../../Assets/TechStackLogo/GoogleCloud.svg";
import HtmlIcon from "../../Assets/TechStackLogo/HTML.svg";
import HerokuIcon from "../../Assets/TechStackLogo/Heroku.svg";
import MapBoxIcon from "../../Assets/TechStackLogo/MapBox.svg";
import MaterialUIIcon from "../../Assets/TechStackLogo/MaterialUI.svg";
import MatplotlibIcon from "../../Assets/TechStackLogo/Matplotlib.svg";
import MongoDBIcon from "../../Assets/TechStackLogo/MongoDB.svg";
import MySQLIcon from "../../Assets/TechStackLogo/MySQL.svg";
import NextAuthIcon from "../../Assets/TechStackLogo/NextAuth.svg";
import NextJSIcon from "../../Assets/TechStackLogo/NextJS.svg";
import NginxIcon from "../../Assets/TechStackLogo/Nginx.svg";
import NodejsIcon from "../../Assets/TechStackLogo/NodeJS.svg";
import NumpyIcon from "../../Assets/TechStackLogo/Numpy.svg";
import OpenCVIcon from "../../Assets/TechStackLogo/OpenCV.svg";
import PrismaIcon from "../../Assets/TechStackLogo/Prisma.svg";
import PytorchIcon from "../../Assets/TechStackLogo/Pytorch.svg";
import ReactjsIcon from "../../Assets/TechStackLogo/React.svg";
import ReacthookformIcon from "../../Assets/TechStackLogo/Reacthookform.svg";
import ReactqueryIcon from "../../Assets/TechStackLogo/Reactquery.svg";
import ReactrouterIcon from "../../Assets/TechStackLogo/Reactrouter.svg";
import RedisIcon from "../../Assets/TechStackLogo/Redis.svg";
import SQLAlchemyIcon from "../../Assets/TechStackLogo/SQLAlchemy.svg";
import SocketIOIcon from "../../Assets/TechStackLogo/SocketIO.svg";
import StylecomponentIcon from "../../Assets/TechStackLogo/Stylecomponent.svg";
import TailwindIcon from "../../Assets/TechStackLogo/Tailwind.svg";
import VercelIcon from "../../Assets/TechStackLogo/Vercel.svg";
import WandbIcon from "../../Assets/TechStackLogo/Wandb.svg";
import WebrtcIcon from "../../Assets/TechStackLogo/Webrtc.svg";
import ZodIcon from "../../Assets/TechStackLogo/Zod.svg";
import OpenAIIcon from "../../Assets/TechStackLogo/openai.svg";
import DjangoIcon from "../../Assets/TechStackLogo/Django.svg";
import FlutterIcon from "../../Assets/TechStackLogo/Flutter.svg";
import PuppeteerIcon from "../../Assets/TechStackLogo/Puppeteer.svg";
import ReduxIcon from "../../Assets/TechStackLogo/Redux.svg";

const frontendIcons = [
  { name: "HTML", icon: HtmlIcon },
  { name: "EJS", icon: EJSIcon },
  { name: "Flutter", icon: FlutterIcon },
  { name: "React.js", icon: ReactjsIcon },
  { name: "Next.js", icon: NextJSIcon },
  { name: "Next Auth", icon: NextAuthIcon },
  { name: "Redux", icon: ReduxIcon },
  { name: "Hook Form", icon: ReacthookformIcon },
  { name: "React Query", icon: ReactqueryIcon },
  { name: "React Router", icon: ReactrouterIcon },
  { name: "CSS", icon: CssIcon },
  { name: "Bootstrap", icon: BootstrapIcon },
  { name: "Tailwind", icon: TailwindIcon },
  { name: "Material UI", icon: MaterialUIIcon },
  { name: "Styled-Component", icon: StylecomponentIcon },
  { name: "Framer motion", icon: FramerIcon },
  { name: "Chart.js", icon: ChartJSIcon },
  { name: "WebRTC", icon: WebrtcIcon },
  { name: "MapBox", icon: MapBoxIcon },
];

const backendIcons = [
  { name: "Node.js", icon: NodejsIcon },
  { name: "Express.js", icon: ExpressjsIcon },
  { name: "Next.js", icon: NextJSIcon },
  { name: "FastAPI", icon: FastAPIIcon },
  { name: "Flask", icon: FlaskIcon },
  { name: "Django", icon: DjangoIcon },
  { name: "Socket.IO", icon: SocketIOIcon },
  { name: "Firebase", icon: FirebaseIcon },
  { name: "Redis", icon: RedisIcon },
  { name: "MongoDB", icon: MongoDBIcon },
  { name: "MySQL", icon: MySQLIcon },
  { name: "Zod", icon: ZodIcon },
  { name: "Prisma", icon: PrismaIcon },
  { name: "SQLAlchemy", icon: SQLAlchemyIcon },
  { name: "OpenAI", icon: OpenAIIcon },
  { name: "Puppeteer", icon: PuppeteerIcon },
];

const machineLearningIcons = [
  { name: "Pytorch", icon: PytorchIcon },
  { name: "Numpy", icon: NumpyIcon },
  { name: "Wandb", icon: WandbIcon },
  { name: "Celerly", icon: CeleryIcon },
  { name: "OpenCV", icon: OpenCVIcon },
  { name: "Matplotlib", icon: MatplotlibIcon },
];

const devOpsIcons = [
  { name: "Docker", icon: DockerIcon },
  { name: "Github action", icon: GithubactionIcon },
  { name: "Git", icon: GitIcon },
  { name: "Nginx", icon: NginxIcon },
  { name: "Aws Ec2", icon: Awsec2Icon },
  { name: "Google Cloud", icon: GoogleCloudIcon },
  { name: "Heroku", icon: HerokuIcon },
  { name: "Vercel", icon: VercelIcon },
];

const CardWrapper = styled.section`
  .stack-heading {
    font-size: 2rem;
    color: white;
  }
  .flip-card {
    background-color: transparent;
    width: 13vw;
    height: 10vw;
    perspective: 1000px;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }

  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  .image-icon {
    width: 4vw;
    height: 4vw;
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    font-size: 0.8rem;
    text-align: center;
    color: white;
    font-family: Georgia, "Times New Roman", Times, serif;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  .flip-card-back {
    transform: rotateY(180deg);
  }
  .tech-icons {
    border: 1.7px solid rgba(200, 137, 230, 0.637);
    box-shadow: 4px 5px 4px 3px rgba(89, 4, 168, 0.137);
    transition: transform 0.4s ease 0s;
    display: flex;
    margin: 2vh;
    justify-content: center;
    align-items: center;
    width: min(15vw, 15vh);
    height: min(15vw, 15vh);
  }
  .tech-icons:hover {
    transform: scale(1.2);
    border: 2.2px solid rgba(197, 115, 230, 0.883);
    transition: border 0.4s ease 0s;
  }
`;
const WaveAnimationVariant = {
  initial: {
    y: 30,
    opacity: 0,
    rotate: 180,
    borderRadius: "0%",
  },
  animate: (index) => ({
    y: 0,
    opacity: 1,
    rotate: 360,
    borderRadius: "30%",
    transition: {
      y: { duration: 0.5, delay: index * 0.04 },
      opacity: { duration: 0.5, delay: index * 0.04 },
      rotate: { duration: 0.5, delay: index * 0.04 },
      borderRadius: { duration: 0.5, delay: index * 0.04 + 0.2 },
    },
  }),
};

function List(props) {
  const techIcons = props.techIcon;
  return (
    <CardWrapper>
      <Row className="justify-content-center" style={{ paddingBottom: "50px" }}>
        <Col
          xs={12}
          className="d-flex justify-content-center align-items-center"
        >
          <h1 className="stack-heading">
            Professional <strong className="purple">Skillset </strong>
          </h1>
        </Col>
        {techIcons.map((techIcon, index) => (
          <Col
            xs={3}
            md={2}
            key={index}
            className="d-flex justify-content-center align-items-center"
          >
            <motion.div
              variants={WaveAnimationVariant}
              initial="initial"
              whileInView="animate"
              whileHover={{ scale: 1.13 }}
              transition={{ duration: 0.5, type: "spring", stiffness: 100 }}
              custom={index}
              className="tech-icons"
            >
              <motion.div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <img src={techIcon.icon} className="image-icon" alt="" />
                  </div>
                  <div className="flip-card-back">{techIcon.name}</div>
                </div>
              </motion.div>
            </motion.div>
          </Col>
        ))}
      </Row>
    </CardWrapper>
  );
}

function Techstack() {
  const tabs = [
    {
      title: "Front End",
      value: "frontend",
      content: (
        <div className="w-full overflow-hidden relative h-full rounded-2xl p-10 text-xl md:text-4xl font-bold text-white bg-gradient-to-br from-purple-700 to-violet-900">
          <List techIcon={frontendIcons} />
        </div>
      ),
    },
    {
      title: "Back End",
      value: "backend",
      content: (
        <div className="w-full overflow-hidden relative h-full rounded-2xl p-10 text-xl md:text-4xl font-bold text-white bg-gradient-to-br from-purple-700 to-violet-900">
          <List techIcon={backendIcons} />
        </div>
      ),
    },
    {
      title: "DevOps",
      value: "playground",
      content: (
        <div className="w-full overflow-hidden relative h-full rounded-2xl p-10 text-xl md:text-4xl font-bold text-white bg-gradient-to-br from-purple-700 to-violet-900">
          <List techIcon={devOpsIcons} />
        </div>
      ),
    },
    {
      title: "Machine Learning",
      value: "machinelearning",
      content: (
        <div className="w-full overflow-hidden relative h-full rounded-2xl p-10 text-xl md:text-4xl font-bold text-white bg-gradient-to-br from-purple-700 to-violet-900">
          <List techIcon={machineLearningIcons} />
        </div>
      ),
    },
  ];

  return (
    <div className="h-[75vh] [perspective:1000px] relative b flex flex-col  mx-auto w-full max-w-4xl  items-start justify-start my-[5vh]">
      <Tabs tabs={tabs} />
    </div>
  );
}

export default Techstack;
