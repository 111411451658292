const FlavorieImages = [];
for (let i = 1; i <= 21; i++) {
  FlavorieImages.push(require(`../../Assets/Projects/Flavorie/${i}.png`));
}

const FlavorieDescription = [
  {
    title: "Home Page",
    description: "Discover new recipes and trending foods.",
  },
  {
    title: "Our Features",
    description: "Explore all features and functionalities.",
  },
  { title: "Feedback", description: "Value your feedback and suggestions." },
  {
    title: "Login Page",
    description: "Securely authenticate users with email and password.",
  },
  {
    title: "Ingredients Page",
    description: "Find all ingredients and some suggestions for you.",
  },
  {
    title: "Category Page",
    description: "Browse through different categories of ingredients.",
  },
  {
    title: "Meal Page",
    description: "Explore daily specials and search for it by name.",
  },
  {
    title: "All Recipes",
    description: "View all recipes and save anything you like.",
  },
  {
    title: "Single Recipe",
    description: "Get detailed information and instructions for each recipe.",
  },
  {
    title: "Upload Receipt",
    description: "Upload your receipt and get the recipe.",
  },
  {
    title: "Notifications Bell",
    description: "Receive notifications for long-processing tasks.",
  },
  {
    title: "Receipt Page",
    description: "Check if we are missing any ingredients then submit.",
  },
  {
    title: "Community Page",
    description: "Share your thoughts and recipes with the community.",
  },
  {
    title: "Post Comment",
    description: "Leave comments and ratings on recipes.",
  },
  { title: "Create Post", description: "Contribute by sharing your ideas." },
  { title: "Join Meeting", description: "Join meeting with other users." },
  { title: "Chat Box", description: "Chat with other users." },
  {
    title: "Cook Together",
    description:
      "Choose your favorite recipe and use hand gestures to control.",
  },
  {
    title: "Profile Page",
    description: "Analyze your nutrition and adjust some allergies.",
  },
  {
    title: "Instacart Connection",
    description: "Connect with Instacart to order right away your ingredients.",
  },
  {
    tilte: "Instacart List",
    description: "We have created a list for you to order your ingredients.",
  },
  {
    title: "System Design",
    description: "Design the system with a clear structure.",
  },

  {
    title: "Deployment",
    description: "Full CI/CD pipeline with Docker and AWS EC2",
  },
];

const InternshipForumImages = [];
for (let i = 1; i <= 14; i++) {
  InternshipForumImages.push(
    require(`../../Assets/Projects/InternshipForum/${i}.png`)
  );
}
const InternshipForumDescription = [
  {
    title: "Home Page",
    description: "Provide with various effects for a captivating home page.",
  },
  {
    title: "Main Page",
    description:
      "Find internship reviews with search and filter functionalities.",
  },
  {
    title: "Bottom Page",
    description: "Implement pagination to easily navigate.",
  },
  {
    title: "Single Internship",
    description: "Present information of that internship.",
  },
  {
    title: "Comment Section",
    description: "Enable users to leave comments and ratings on reviews.",
  },
  {
    title: "Create Post",
    description: "Let logged-in users to contribute by creating new post.",
  },
  {
    title: "Profile View Page",
    description: "Display user's profile information along with their reviews.",
  },
  {
    title: "Login Page",
    description: "Securely authenticate users with email and password.",
  },
  {
    title: "Radar Chart",
    description: "Compare any chosen companies using a radar chart.",
  },
  {
    title: "Bar Chart",
    description:
      "Visualize the average statistics of each state with interactive chart.",
  },
  {
    title: "Line-Stacked Chart",
    description:
      "Explore monthly statistics with insightful line-stacked chart.",
  },
  {
    title: "Map",
    description:
      "Navigate through a cluster map showcasing all internship's location.",
  },
  {
    title: "Light Theme Support",
    description: "Enhance user experience with a customizable light theme.",
  },
  {
    title: "Theme Customization",
    description:
      "Remember user theme preferences for a personalized experience.",
  },
];
const ChromaMixImages = [];
for (let i = 1; i <= 5; i++) {
  ChromaMixImages.push(require(`../../Assets/Projects/ChromaMix/${i}.png`));
}
const ChromaMixDescription = [
  {
    title: "Home Page",
    description: "Explore popular color palettes and find inspiration.",
  },
  {
    title: "Individual Palette",
    description: "Copy hex codes easily for seamless integration.",
  },
  {
    title: "Palette Zoom Page",
    description:
      "Dive into colors at a larger scale with a handy tool toolbar.",
  },
  {
    title: "Tint and Shade Page",
    description: "Generate beautiful tints and shades for any chosen color.",
  },
  {
    title: "Color Picker Page",
    description: "Pick any color from the color wheel.",
  },
];
const InternshipForumV1 = [];
for (let i = 1; i <= 8; i++) {
  InternshipForumV1.push(
    require(`../../Assets/Projects/InternshipForumV1/${i}.png`)
  );
}
const InternshipForumV1Description = [
  {
    title: "Home Page",
    description: "Enhance user experience with captivating effects.",
  },
  {
    title: "Display Page",
    description: "Discover internship reviews with search features.",
  },
  {
    title: "Close up view",
    description: "Concise information and navigation nutton ",
  },
  { title: "Single Post Page", description: "Present detailed informations." },
  {
    title: "Profile View Page",
    description: "Show users' profiles along with their valuable reviews.",
  },
  {
    title: "Register Page",
    description: "Securely register users through email and password.",
  },
  {
    title: "Contribute Page",
    description: "Empower users to share new internship reviews.",
  },
  {
    title: "Statistics Page",
    description: "Visualize key statistics for insightful analysis.",
  },
];
const SeamLessLMSImages = [];
for (let i = 1; i <= 9; i++) {
  SeamLessLMSImages.push(require(`../../Assets/Projects/SeamLessLMS/${i}.png`));
}
const SeamLessLMSDescription = [
  {
    title: "Login Page",
    description: "Securely authenticate users with email and password.",
  },
  {
    title: "Admin Dashboard",
    description: "Manage users, courses, and other resources.",
  },
  {
    title: "Role Imitation",
    description: "Imitate different roles to test functionalities.",
  },
  {
    title: "User Creation",
    description: "Create new users with different roles.",
  },
  {
    title: "Course Creation",
    description: "Make new courses with different categories.",
  },
  {
    title: "Assignment Page",
    description: "Establish new assignments corresponding to courses.",
  },
  { title: "Location Page", description: "Current location of the user." },
  {
    title: "Filter Tree",
    description: "Combine And and Or filters to search for data.",
  },
  {
    title: "Column Projection",
    description: "Choose which columns to display in the table.",
  },
];
const CodeBuddyImages = [];
for (let i = 1; i <= 9; i++) {
  CodeBuddyImages.push(require(`../../Assets/Projects/CodeBuddy/${i}.png`));
}
const BuddyCodeDescription = [
  {
    title: "Landing Page",
    description: "Join a room or create a new one to start coding.",
  },
  {
    title: "Adjustable Editor",
    description:
      "Modify the editor's size or minimize it, similar to the functionality in Leetcode.",
  },
  {
    title: "Waiting Screen",
    description: "Share code for your friends to join the room.",
  },
  {
    title: "Blur Effect",
    description: "Blur the code at beginning if you don't want to see yet.",
  },
  {
    title: "Tabs Switching",
    description: "Switch between tabs to see different code snippets.",
  },
  {
    title: "Real-time Collaboration",
    description: "See the changes updates line by line specifically.",
  },
  {
    title: "Reloading Effect",
    description:
      "The connection is still persistent even if you reload the page.",
  },
  {
    title: "Paste Code",
    description: "If you want to run your friends code or modify it.",
  },
  {
    title: "Leave Room",
    description: "You can leave the room at any time and join another one.",
  },
];

const TripSyncImages = [];
for (let i = 1; i <= 6; i++) {
  TripSyncImages.push(require(`../../Assets/Projects/TripSync/${i}.png`));
}
const TripSyncDescription = [
  {
    title: "Welcome Page",
    description: "Welcome to TripSync, a travel planning mobile application.",
  },

  {
    title: "Login Page",
    description: "Securely authenticate users with email and password.",
  },

  {
    title: "Home Page",
    description: "Discover new trips and trending destinations.",
  },
  {
    title: "Search Screen",
    description: "Search for trips and destinations.",
  },
  {
    title: "Calendar Page",
    description: "View all trips and events in a calendar view.",
  },
  {
    title: "Community Feed",
    description: "Share your trips and experiences with the community.",
  },
];
const description = {
  Flavorie: {
    description: FlavorieDescription.map((item, index) => ({
      ...item,
      src: FlavorieImages[index],
    })),
    link: "https://flavorie.xyz",
  },
  CodeBuddy: {
    description: BuddyCodeDescription.map((item, index) => ({
      ...item,
      src: CodeBuddyImages[index],
    })),
    link: "https://github.com/hungngodev/Code-Buddy-Prod",
  },
  TripSync: {
    description: TripSyncDescription.map((item, index) => ({
      ...item,
      src: TripSyncImages[index],
    })),
    link: "https://github.com/hungngodev/TripSync",
  },
  InternshipForum: {
    description: InternshipForumDescription.map((item, index) => ({
      ...item,
      src: InternshipForumImages[index],
    })),
    link: "https://internshipforum-80226f6855ff.herokuapp.com",
  },
  ChromaMix: {
    description: ChromaMixDescription.map((item, index) => ({
      ...item,
      src: ChromaMixImages[index],
    })),
    link: "https://hungngodev.github.io/ChromaMix",
  },
  InternshipForumV1: {
    description: InternshipForumV1Description.map((item, index) => ({
      ...item,
      src: InternshipForumV1[index],
    })),
    link: "https://internshipforumv1-1bc3d87eae50.herokuapp.com/internships/new",
  },
  SeamLessLMS: {
    description: SeamLessLMSDescription.map((item, index) => ({
      ...item,
      src: SeamLessLMSImages[index],
    })),
    link: "https://github.com/Seamless-Tech",
  },
};
export default description;
